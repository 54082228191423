const state = {
	players: [],
	player: {},
	newPlayer: {},
	loading: false,
	error: null,
	hasError: false,
	playerBets: []
};

export default state;
