const mutations = {
  SET_LOADING: (state, payload) => {
    state.loading = payload;
  },

  SET_ERROR: (state, payload) => {
    state.error = payload;
  },

  SET_COMMISSIONS: (state, payload) => {
    state.commissions = payload;
  },

  SET_COMMISSION: (state, payload) => {
    state.commission = payload;
  },

  SET_AGENTS: (state, payload) => {
    state.agents = payload;
  },

  SET_AGENT: (state, payload) => {
    state.agent = payload;
  },
  SET_MAXLOG: (state, payload) => {
    state.maxCountLog = payload;
  },
};

export default mutations;
