import state from './moduleLobbyState';
import mutations from './moduleLobbyMutations';
import actions from './moduleLobbyActions';
import getters from './moduleLobbyGetters';

const store = {
	namespaced: true,
	state,
	mutations,
	actions,
	getters
};

export default store;
