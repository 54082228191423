const getters = {
	getWheelLobbies: state => {
		return state.wheelLobbies.map(lobby => {
			lobby.minBet = lobby.lobbyRestrictions.minBet;
			lobby.maxTotalBets = lobby.lobbyRestrictions.maxTotalBets;
			lobby.betCount = lobby.bets.length;
			lobby.totalBets = lobby.bets.reduce((sum, bet) => parseFloat(sum) + parseFloat(bet.amount), 0);
			return lobby;
		});
	},

	getWheelParticipants: state => {
		if (!!state.wheelLobby == true) {
			let min = 0;
			const totalBets = state.wheelLobby.bets.reduce((sum, bet) => parseFloat(sum) + parseFloat(bet.amount), 0);
			return state.wheelLobby.bets.map(bet => {
				const piePercentage = parseFloat((bet.amount / totalBets) * 100).toFixed(2);
				const max = 10000 * (piePercentage / 100);
				const newMin = parseFloat(min) + parseFloat(max) - 1;
				bet.pie_percentage = piePercentage;
				bet.full_name = bet.player.full_name;
				bet.display_name = bet.player.display_name;
				bet.number_range = {
					min: min.toFixed(2),
					max: newMin.toFixed(2)
				};

				min = newMin + 1;

				return bet;
			});
		} else {
			return null;
		}
	},

	getWheelWinners: state => {
		return !!state.wheelLobby == true ? state.wheelLobby.winners : [];
	},

	getWheelTotalBets: state => {
		if (!!state.wheelLobby == true) {
			return state.wheelLobby.bets.reduce((sum, bet) => parseFloat(sum) + parseFloat(bet.amount), 0);
		} else {
			return 0;
		}
	}
};

export default getters;
