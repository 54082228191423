const state = {
	coinSelected: 3,
	lobbies: [],
	myLobbies: [],
	lobby: {},
	loading: false,
	error: null,
	hasError: false,
	lobbyFlipFee: process.env.VUE_APP_LOBBY_FLIP_FEE || 2.5,
	lobbyWheelFee: process.env.VUE_APP_LOBBY_WHEEL_FEE || 5,
	wheelPlayers: [],
	wheelLobbies: [],
	wheelLobby: null
};

export default state;
