const mutations = {

	SET_SELECTED_COIN: (state, payload) => { 
		state.coinSelected = payload;
	},

	SET_LOADING: (state, payload) => {
		state.loading = payload;
	},

	SET_ERROR: (state, payload) => {
		state.hasError = !!payload;
		state.error = payload;
	},

	/** coinflip lobbies */
	SET_LOBBIES: (state, payload) => {
		state.lobbies = payload;
	},

	SET_LOBBY: (state, payload) => {
		state.lobby = payload;
	},

	SET_MY_LOBBIES: (state, payload) => {
		state.myLobbies = payload;
	},

	/**ADDING MORE LOBBIES TO MY GAMES */
	SET_MORE_MY_LOBBIES: (state, payload) => {

		if (Array.isArray(payload)) {
			//DEPRECATED
			// if( !(state.myLobbies.some(lobby => lobby._id === payload[0]._id)) ) { // check if lobby added in mylobbies already
			// 	state.myLobbies = state.myLobbies.concat(payload).sort((a,b) => b.sequenceId - a.sequenceId);
			// }
			state.myLobbies = state.myLobbies.concat(payload.filter(p => !state.myLobbies.find(pl => pl._id == p._id)));
		} else {
			state.myLobbies = state.myLobbies.concat([payload]).sort((a,b) => b.sequenceId - a.sequenceId);
		}
		state.myLobbies.sort((a,b) => b.sequenceId - a.sequenceId);
	},

	INSERT_NEW_COINFLIP_LOBBY: (state, payload) => {
		if (Array.isArray(payload)) {
			state.lobbies = state.lobbies.concat(payload.filter(p =>  !state.lobbies.find(pl => pl._id == p._id)));
		} else {
			state.lobbies = state.lobbies.concat([payload]);
		}
		state.lobbies.sort((a,b) => b.sequenceId - a.sequenceId);
	},

	REMOVE_LOBBY_FROM_MY_LOBBIES: (state, payload) => { 

		if (payload.status != 0) {
			state.myLobbies = state.myLobbies.filter(lobby => lobby._id != payload);
		}
	},

	REMOVE_LOBBY_FROM_LOBBIES: (state, payload) => { 
		if (payload.status != 0) {
			state.lobbies = state.lobbies.filter(lobby => lobby._id != payload);

			
		}
	},

	SET_LOBBY_IN_LOBBIES: (state, payload) => {
		if (payload.status == 1) {
			payload.startAnimation = true;
		}
		state.lobbies = state.lobbies.map(lobby => {
			return lobby._id == payload._id ? payload : lobby;
		});
	},

	SET_LOBBY_IN_MY_LOBBIES: (state, payload) => {
		if (payload.status == 1) {
			payload.startAnimation = true;
		}
		state.myLobbies = state.myLobbies.map(lobby => {
			return lobby._id == payload._id ? payload : lobby;
		});
	},
	



	INSERT_IN_WHEEL_PLAYERS: (state, payload) => {
		state.wheelPlayers = [...state.wheelPlayers.filter(player => player._id != payload._id), ...[payload]];
	},

	SET_WHEEL_LOBBIES: (state, payload) => {
		state.wheelLobbies = payload;
	},

	SET_WHEEL_LOBBY: (state, payload) => {
		state.wheelLobby = payload;
	}
};

export default mutations;
