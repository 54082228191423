const mutations = {
	SET_USER: (state, payload) => {
		state.user = payload;
	},

	SET_WALLET: (state, payload) => {
		state.wallet = payload;
	},

	SET_HAS_NEW_MESSAGE: (state, payload) => {
		state.hasNewMessage = payload;
	},

	SET_TOGGLE_CHAT_BOX: (state, payload) => {
		state.showChatBox = payload;
	},

	SET_USER_AVATAR: (state, payload) => {
		state.user.avatar_type =
			!!payload.avatar_type == true ? payload.avatar_type : !!state.avatar_type == true ? state.avatar_type : 'avatar';
		state.user.avatar_options =
			!!payload.avatar_options == true
				? payload.avatar_options
				: !!state.avatar_options == true
				? state.avatar_options
				: null;
		state.user.display_photo =
			!!payload.display_photo == true
				? payload.display_photo
				: !!state.display_photo == true
				? state.display_photo
				: null;
	},

	SET_USER_DISPLAY_NAME: (state, payload) => {
		state.user.display_name = payload;
	}
};

export default mutations;
