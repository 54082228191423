import axiosConfig from '../../axios';

const { axiosPlayer, axiosAgent } = axiosConfig;

const actions = {
	//update authenticated user
	setUser({ commit }, payload) {
		commit('SET_USER', payload);
	},

	setWallet({ commit }, payload) {
		commit('SET_WALLET', payload);
	},

	setHasNewMessage({ commit }, payload) {
		commit('SET_HAS_NEW_MESSAGE', payload);
	},

	setToggleChatBox({ commit }, payload) {
		commit('SET_TOGGLE_CHAT_BOX', payload);
	},

	setUserAvatar({ commit }, payload) {
		commit('SET_USER_AVATAR', payload);
	},

	setUserDisplayName({ commit }, payload) {
		commit('SET_USER_DISPLAY_NAME', payload);
	},

	async getWallet({ commit }, payload) {
		const response = await axiosPlayer.get(`/wallet/${payload}`);
		const { data } = response;
		const { result: wallet } = data;
		commit('SET_WALLET', wallet);
		return wallet;
	},

	async getAgentWallet({ commit }, payload) {
		const response = await axiosAgent.get(`/wallet/${payload}`);
		const { data } = response;
		const { result: wallet } = data;
		console.log('SET_WALLET: ', wallet);
		commit('SET_WALLET', wallet);
		return wallet;
	}
};

export default actions;
