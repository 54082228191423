const mutations = {
  SET_LOADING: (state, payload) => {
    state.loading = payload;
  },

  SET_ERROR: (state, payload) => {
    state.error = payload;
  },

  SET_USERS: (state, payload) => {
    state.users = payload;
  },

  SET_USER: (state, payload) => {
    state.user = payload;
  },
};

export default mutations;
