const mutations = {
  SET_LOADING: (state, payload) => {
    state.loading = payload;
  },

  SET_ERROR: (state, payload) => {
    state.error = payload;
    state.hasError = !!payload;
  },

  SET_TRANSACTIONS: (state, payload) => {
    state.transactions = payload;
  },

  SET_TRANSACTION: (state, payload) => {
    state.transaction = payload;
  },

  SET_NEW_TRANSACTION: (state, payload) => {
    state.newTransaction = payload;
  },

  SET_TRANSACTION_STATUS: (state, payload) => {
    state.transactions[payload.index].status = payload.updateStatus;
  },
  SET_MAXLOG: (state, payload) => {
    state.maxCountLog = payload;
  },
};

export default mutations;
