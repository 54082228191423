import axiosConfig from "../../axios";

const { axiosTransaction: axios } = axiosConfig;

const actions = {
  /**
   * API call for creating transaction | For players
   */
  async createDepositTransaction({ commit }, payload) {
    commit("SET_LOADING", true);
    commit("SET_ERROR", null);

    try {
      const response = await axios.post("/deposit", payload);
      const { data } = response;
      const { result: transaction, success, message } = data;
      commit("SET_LOADING", false);
      commit("SET_TRANSACTION", transaction);
      return {
        success,
        message,
        agentWallet: transaction.agentWallet,
      };
    } catch (error) {
      commit("SET_LOADING", false);
      const { response } = error;
      const { data } = response;
      const { result: transaction, success, message } = data;

      commit("SET_ERROR", message);
      commit("SET_TRANSACTION", transaction);
      return {
        success,
        message,
      };
    }
  },

  /**
   * API call for creating cashout request transaction | For players
   */
  async createCashoutRequestTransaction({ commit }, payload) {
    commit("SET_LOADING", true);
    commit("SET_ERROR", null);

    try {
      const response = await axios.post("/withdraw/request", payload);
      const { data } = response;
      const { result, success, message } = data;
      const { transaction, wallet } = result;
      commit("SET_LOADING", false);
      commit("SET_TRANSACTION", transaction);
      return {
        success,
        message,
        transaction,
        wallet: wallet,
      };
    } catch (error) {
      commit("SET_LOADING", false);
      const { response } = error;
      const { data } = response;
      const { result: transaction, success, message } = data;

      commit("SET_ERROR", message);
      commit("SET_TRANSACTION", transaction);
      return {
        success,
        message,
      };
    }
  },

  /**
   * API call for creating transaction | For agents
   */

  async createAgentDepositTransaction({ commit }, payload) {
    commit("SET_LOADING", true);
    commit("SET_ERROR", null);

    try {
      const response = await axios.post("/agent/deposit", payload);
      const { data } = response;
      const { result: transaction, success, message } = data;
      commit("SET_LOADING", false);
      commit("SET_TRANSACTION", transaction);
      return {
        success,
        message,
        agentWallet: transaction.agentWallet,
      };
    } catch (error) {
      commit("SET_LOADING", false);
      const { response } = error;
      const { data } = response;
      const { result: transaction, success, message } = data;

      commit("SET_ERROR", message);
      commit("SET_TRANSACTION", transaction);
      return {
        success,
        message,
      };
    }
  },

  async getTransactions({ commit }, payload) {
    commit("SET_LOADING", true);
    commit("SET_TRANSACTIONS", []);

    try {
      await axios
        .get("/logs", { params: payload })
        .then(response => {
          console.log("response data", response.data.result);
          commit("SET_TRANSACTIONS", response.data.result.transactions);
          commit("SET_MAXLOG", response.data.result.count);
        })
        .catch(error => {
          const { response } = error;
          commit("SET_ERROR", response.data.error);
        });

      commit("SET_LOADING", false);
    } catch (error) {
      const { response } = error;

      if (!!response.data == true) {
        const { message } = response.data;
        commit("SET_ERROR", message);
        return false;
      }

      commit("SET_LOADING", false);
    }
  },

  async processTransaction({ commit }, payload) {
    commit("SET_LOADING", true);
    commit("SET_ERROR", null);

    try {
      await axios
        .put("/process-transaction", payload)
        .then(response => {
          commit("SET_TRANSACTION", response.data.result);
          console.log("xxx", response.data.result);
        })
        .catch(error => {
          const { response } = error;
          commit("SET_ERROR", response.data.error);
          return false;
        });

      commit("SET_LOADING", false);
    } catch (error) {
      const { response } = error;

      if (!!response.data == true) {
        const { message } = response.data;
        commit("SET_ERROR", message);
        return false;
      }

      commit("SET_LOADING", false);
    }
  },

  async setTransactionByIndex({ commit }, payload) {
    commit("SET_TRANSACTION_STATUS", payload);
  },

  async getAgentPlayerDeposits({ commit }, payload) {
    commit("SET_LOADING", true);
    commit("SET_TRANSACTIONS", []);
    const { agentId, type } = payload;
    const url = `agents/${agentId}/players/${type}`;
    try {
      await axios
        .get(url)
        .then(response => {
          commit("SET_TRANSACTIONS", response.data.result);
        })
        .catch(error => {
          const { response } = error;
          commit("SET_ERROR", response.data.error);
        });

      commit("SET_LOADING", false);
    } catch (error) {
      const { response } = error;

      if (!!response.data == true) {
        const { message } = response.data;
        commit("SET_ERROR", message);
        return false;
      }

      commit("SET_LOADING", false);
    }
  },

  async getAgentPlayerCashouts({ commit }, payload) {
    commit("SET_LOADING", true);
    commit("SET_TRANSACTIONS", []);
    const { agentId, type } = payload;
    const url = `agents/${agentId}/players/${type}`;
    try {
      await axios
        .get(url)
        .then(response => {
          commit("SET_TRANSACTIONS", response.data.result);
        })
        .catch(error => {
          const { response } = error;
          commit("SET_ERROR", response.data.error);
        });

      commit("SET_LOADING", false);
    } catch (error) {
      const { response } = error;

      if (!!response.data == true) {
        const { message } = response.data;
        commit("SET_ERROR", message);
        return false;
      }

      commit("SET_LOADING", false);
    }
  },

  async getAgentSubsDeposits({ commit }, payload) {
    commit("SET_LOADING", true);
    commit("SET_TRANSACTIONS", []);
    const { agentId, type } = payload;
    const url = `agents/${agentId}/subs/${type}`;
    try {
      await axios
        .get(url)
        .then(response => {
          commit("SET_TRANSACTIONS", response.data.result);
        })
        .catch(error => {
          const { response } = error;
          commit("SET_ERROR", response.data.error);
        });

      commit("SET_LOADING", false);
    } catch (error) {
      const { response } = error;

      if (!!response.data == true) {
        const { message } = response.data;
        commit("SET_ERROR", message);
        return false;
      }

      commit("SET_LOADING", false);
    }
  },

  async getAgentSubsCashouts({ commit }, payload) {
    commit("SET_LOADING", true);
    commit("SET_TRANSACTIONS", []);
    const { agentId, type } = payload;
    const url = `agents/${agentId}/subs/${type}`;
    try {
      await axios
        .get(url)
        .then(response => {
          commit("SET_TRANSACTIONS", response.data.result);
        })
        .catch(error => {
          const { response } = error;
          commit("SET_ERROR", response.data.error);
        });

      commit("SET_LOADING", false);
    } catch (error) {
      const { response } = error;

      if (!!response.data == true) {
        const { message } = response.data;
        commit("SET_ERROR", message);
        return false;
      }

      commit("SET_LOADING", false);
    }
  },

  async getPlayerTransactions({ commit }, payload) {
    commit("SET_LOADING", true);
    commit("SET_TRANSACTIONS", []);
    const { playerId } = payload;
    const url = `players/${playerId}`;
    try {
      await axios
        .get(url)
        .then(response => {
          commit("SET_TRANSACTIONS", response.data.result);
        })
        .catch(error => {
          const { response } = error;
          commit("SET_ERROR", response.data.error);
        });

      commit("SET_LOADING", false);
    } catch (error) {
      const { response } = error;

      if (!!response.data == true) {
        const { message } = response.data;
        commit("SET_ERROR", message);
        return false;
      }

      commit("SET_LOADING", false);
    }
  },

  /**
   * API call for approving or rejecting tranasction of player
   */
  async processPlayerTransaction({ commit }, payload) {
    console.log("payload", payload);
    commit("SET_LOADING", true);
    commit("SET_ERROR", null);

    const { _id: id, status } = payload;
    let url = `/withdraw/${status}/${id}`;

    try {
      const response = await axios.put(url, payload);
      const { data } = response;
      const { result: transaction, success, message } = data;
      console.log("dataa", data);
      console.log("result", transaction);
      console.log("success", success);
      console.log("message", message);
      commit("SET_LOADING", false);
      commit("SET_TRANSACTION", transaction);
      return {
        success,
        message,
        agentWallet: transaction.agentWallet,
      };
    } catch (error) {
      commit("SET_LOADING", false);
      const { response } = error;
      const { data } = response;
      const { result: transaction, success, message } = data;

      commit("SET_ERROR", message);
      commit("SET_TRANSACTION", transaction);
      return {
        success,
        message,
      };
    }
  },

  /**
   * API call for approving or rejecting transaction of agent
   */

  async processAgentTransaction({ commit }, payload) {
    console.log("payload", payload);
    commit("SET_LOADING", true);
    commit("SET_ERROR", null);

    const { _id: id, status } = payload;
    let url = `/withdraw/agent/${status}/${id}`;

    try {
      const response = await axios.put(url, payload);
      const { data } = response;
      const { result: transaction, success, message } = data;
      console.log("dataa", data);

      commit("SET_LOADING", false);
      commit("SET_TRANSACTION", transaction);
      return {
        success,
        message,
        agentWallet: transaction,
      };
    } catch (error) {
      commit("SET_LOADING", false);
      const { response } = error;
      const { data } = response;
      const { result: transaction, success, message } = data;

      commit("SET_ERROR", message);
      commit("SET_TRANSACTION", transaction);
      return {
        success,
        message,
      };
    }
  },
};

export default actions;
