<template>
	<v-app>
		<component :is="layout">
			<router-view :layout.sync="layout" />
		</component>
	</v-app>
</template>

<script>
	export default {
		name: `App`,
		data() {
			return {
				layout: `div`
			};
		}
	};
</script>

<style lang="scss">
	* {
		margin: 0;
		padding: 0;
	}
	html {
		line-height: 1.6;
		color: #333;
		font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
		background-color: #f5f5f5 !important;
	}
	p {
		&:not(:first-child) {
			margin-top: 1.25em;
		}
	}
</style>
