import axios from 'axios';

const AgentBaseURL = `${process.env.VUE_APP_AGENTS_API}`;
const PlayerBaseURL = `${process.env.VUE_APP_PLAYERS_API}`;
const LobbyBaseURL = `${process.env.VUE_APP_LOBBY_API}`;
const UserBaseURL = `${process.env.VUE_APP_USERS_API}`;
const TransactionBaseURL = `${process.env.VUE_APP_TRANSACTIONS_API}`;
const ProviderBaseURL = `${process.env.VUE_APP_PROVIDER_API}`;

const headers = {
	'Access-Control-Allow-Origin': '*',
	'Access-Control-Allow-Headers': `{$_SERVER['HTTP_ACCESS_CONTROL_REQUEST_HEADERS']}`
};

if (window.$cookies.get('vuex')) {
	const token = window.$cookies.get('vuex').auth ? window.$cookies.get('vuex').auth.user.token : '';
	headers['Authorization'] = 'Bearer ' + token;
}

export default {
	axiosAgent: axios.create({
		baseURL: AgentBaseURL,
		headers
	}),
	axiosPlayer: axios.create({
		baseURL: PlayerBaseURL,
		headers
	}),
	axiosLobby: axios.create({
		baseURL: LobbyBaseURL,
		headers
	}),
	axiosUser: axios.create({
		baseURL: UserBaseURL,
		headers
	}),
	axiosTransaction: axios.create({
		baseURL: TransactionBaseURL,
		headers
	}),
	axiosProvider: axios.create({
		baseURL: ProviderBaseURL,
		headers
	}),
};
