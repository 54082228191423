const state = {
  commissions: [],
  commission: {},
  agents: [],
  agent: {},
  loading: false,
  error: null,
  hasError: false,
  commissionCount: 0,
  commissionLimit: 10,
  maxCountLog: 0,
};

export default state;
