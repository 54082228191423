import state from './moduleAgentState';
import mutations from './moduleAgentMutations';
import actions from './moduleAgentActions';
import getters from './moduleAgentGetters';

const store = {
	namespaced: true,
	state,
	mutations,
	actions,
	getters
};

export default store;
