import Vue from 'vue';
import Router from 'vue-router';
Vue.use(Router);


const router = new Router({
    routes: [
        {
            path: '/play/:key',
			name: 'provider',
			component: () => import("../layouts/LayoutDefault.vue"),
			children: [
				{
					path: '/play/:key',
					name: 'Home',
					component: ()=>  import("../pages/Coinflip.vue"),
					meta: {
						guest: false,
						showHeader: true,
						roles: ['player'],
						showChat: true
					}
				}
			]
        }
		
	],
	mode: 'history'
});

// eslint-disable-next-line no-unused-vars
router.beforeEach((to, from, next) => {
	next()
});

export default router;
