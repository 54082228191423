import Vue from "vue";
import Vuetify from "vuetify/lib";
//import 'material-design-icons-iconfont/dist/material-design-icons.css';
import "@mdi/font/css/materialdesignicons.min.css";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    dark: true,
    themes: {
      dark: {
        success: "#006d24",
        default: "2CAB62",
        bet: "#EE6540",
      },
    },
  },
  icons: {
    iconfont: "mdi",
  },
});
