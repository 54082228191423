const state = {
  transactions: [],
  transaction: {},
  newTransaction: {},
  loading: false,
  error: null,
  hasError: false,
  transactionCount: 0,
  transactionLimit: 10,
  maxCountLog: 0,
};

export default state;
