import axiosConfig from '../../axios';

const { axiosLobby: axios } = axiosConfig;

const actions = {
	//action for updating state.lobby data
	setLobby({ commit }, payload) {
		commit('SET_LOBBY', payload);
	},

	/**
	 *
	 * API call for getting all list of lobbies/games
	 */
	async getLobbies({ commit }) {
		const response = await axios.get('/');
		const { data } = response;
		const { result: lobbies } = data;
		commit('SET_LOBBIES', lobbies);
		return lobbies;
	},

	/**
	 *
	 * API call for getting all list of lobbies/games
	 */
	async getOpenActiveLobbies({ commit },payload) {
		const response = await axios.get('/open-active',{params:payload});
		const { data } = response;
		const { result: lobbies } = data;
		if (payload.newData) {
			commit('SET_LOBBIES', lobbies);
		}
		else{
			commit('INSERT_NEW_COINFLIP_LOBBY', lobbies);
		}
		return lobbies;
	},

	/**
	 *
	 * API call for getting all list of lobbies/games
	 */
	async getMyLobbies({ commit },payload) {
		const response = await axios.get(`/player/active`,{params:payload});
		const { data } = response;
		const { result: lobbies } = data;
		commit('SET_MORE_MY_LOBBIES', lobbies);
		return lobbies;
	},

	/**
	 *
	 * API call for creating new lobby/game
	 */
	async createLobby({ commit }, payload) {
		commit('SET_LOADING', true);
		commit('SET_ERROR', null);
		try {
			const response = await axios.post('/', payload);
			const { data } = response;
			const { result: createdLobby } = data;
			commit('SET_LOADING', false);
			return createdLobby;
		} catch (error) {
			const { response } = error;
			if (!!response.data == true) {
				const { message } = response.data;
				commit('SET_ERROR', message);
				return false;
			}

			commit('SET_LOADING', false);
		}
	},

	/**
	 *
	 * API call for creating new lobby/game
	 */
	async bulkCreateLobbies({ commit }, payload) {
		commit('SET_LOADING', true);
		commit('SET_ERROR', null);
		try {
			const response = await axios.post('/bulk', payload);
			const { data } = response;
			const { result: createdLobby } = data;
			commit('SET_LOADING', false);
			// commit('SET_MORE_MY_LOBBIES', createdLobby);
			return createdLobby;
		} catch (error) {
			const { response } = error;
			if (!!response.data == true) {
				const { message,result } = response.data;
				commit('SET_ERROR', message);
				commit('SET_LOADING', false);
				return result ? result : false;
			}

			commit('SET_LOADING', false);
		}
	},

	/**
	 * API call for getting lobby/game by _id
	 */
	async getLobbyBySequencId({ commit }, payload) {
		commit('SET_LOADING', true);
		commit('SET_ERROR', null);

		const id = payload;

		try {
			const response = await axios.get(`/sequence/${id}`);
			const { data } = response;
			const { result: lobby } = data;
			commit('SET_LOADING', false);
			switch (lobby.lobbyType) {
				case 'flip':
					commit('SET_LOBBY', lobby);
					break;
				case 'wheel':
					commit('SET_WHEEL_LOBBY', lobby);
					break;
			}

			return lobby;
		} catch (error) {
			const { response } = error;
			if (!!response.data == true) {
				const { message } = response.data;
				commit('SET_ERROR', message);
				return false;
			}

			commit('SET_LOADING', false);
		}
	},

	/**
	 * API call for getting lobby/game by _id
	 */
	async getLobbyById({ commit }, payload) {
		commit('SET_LOADING', true);
		commit('SET_ERROR', null);

		const id = payload;

		try {
			const response = await axios.get(`/${id}`);
			const { data } = response;
			const { result: lobby } = data;
			commit('SET_LOADING', false);
			switch (lobby.lobbyType) {
				case 'flip':
					commit('SET_LOBBY', lobby);
					break;
				case 'wheel':
					commit('SET_WHEEL_LOBBY', lobby);
					break;
			}

			return lobby;
		} catch (error) {
			const { response } = error;
			if (!!response.data == true) {
				const { message } = response.data;
				commit('SET_ERROR', message);
				return false;
			}

			commit('SET_LOADING', false);
		}
	},

	/**
	 * API call for updating lobby/game
	 */

	async updateLobby({ commit }, payload) {
		commit('SET_LOADING', true);
		commit('SET_ERROR', null);
		try {
			const { id, ...lobbyUpdatedData } = payload;
			const response = await axios.put(`/${id}`, lobbyUpdatedData);
			const { data } = response;
			const { result: lobby } = data;
			commit('SET_AGENT', lobby);
			commit('SET_LOADING', false);
			console.log('lobby : ', lobby);
			return lobby;
		} catch (error) {
			const { response } = error;
			if (!!response.data == true) {
				const { message } = response.data;
				commit('SET_ERROR', message);
				return false;
			}

			commit('SET_LOADING', false);
		}
	},

	/**
	 * Joining lobby
	 * payload = lobby._id
	 */
	async joinFlipLobby({ commit }, payload) {
		commit('SET_LOADING', true);
		commit('SET_ERROR', null);
		try {
			const response = await axios.post('/flip/join', payload);
			const { data } = response;
			const { result: joinedLobby } = data;
			commit('SET_LOADING', false);
			commit('SET_LOBBY_IN_LOBBIES', joinedLobby);
			return joinedLobby;
		} catch (error) {
			const { response } = error;
			if (!!response.data == true) {
				const { message } = response.data;
				commit('SET_LOADING', false);
				commit('SET_ERROR', message);
				return false;
			}
		}
	},

	/**
	 *
	 * API call for getting all list of lobbies/games
	 */
	async getOpenActiveWheelLobbies({ commit }) {
		const response = await axios.get('/wheel/lobbies');
		const { data } = response;
		const { result: lobbies } = data;
		commit('SET_WHEEL_LOBBIES', lobbies);
		return lobbies;
	},

	async joinWheelLobby({ commit }, payload) {
		commit('SET_LOADING', false);

		commit('SET_LOADING', true);
		commit('SET_ERROR', null);
		try {
			const response = await axios.post('/wheel/join', payload);
			const { data } = response;
			const { result: joinedLobby } = data;
			commit('SET_LOADING', false);
			commit('SET_LOBBY_IN_LOBBIES', joinedLobby);
			return joinedLobby;
		} catch (error) {
			const { response } = error;
			if (!!response.data == true) {
				const { message } = response.data;
				commit('SET_LOADING', false);
				commit('SET_ERROR', message);
				return false;
			}
		}
		return await payload;
	},

	insertPlayerToWheelPlayers({ commit }, payload) {
		commit('INSERT_IN_WHEEL_PLAYERS', payload);
	},

	async getNextWheelLobby({ commit }, payload) {
		commit('SET_LOADING', false);
		commit('SET_LOADING', true);
		commit('SET_ERROR', null);

		try {
			const response = await axios.get('/wheel/open/with-restrictions', { params: { ...payload } });
			const { data } = response;
			const { result: joinedLobby } = data;
			// commit('SET_LOADING', false);
			// commit('SET_LOBBY_IN_LOBBIES', joinedLobby);
			return joinedLobby;
		} catch (error) {
			const { response } = error;
			if (!!response.data == true) {
				const { message } = response.data;
				commit('SET_LOADING', false);
				commit('SET_ERROR', message);
				return false;
			}
		}
	},

	insertNewCoinflipLobby({ commit }, payload) {
		commit('INSERT_NEW_COINFLIP_LOBBY', payload);
	},

	updateCoinflipLobby({ commit }, payload) {
		commit('SET_LOBBY_IN_LOBBIES', payload);
	},

	updateCoinflipMyLobby({ commit }, payload) {
		commit('SET_LOBBY_IN_MY_LOBBIES', payload);
	},

	insertNewCoinflipOwnLobby({ commit }, payload) {
		commit('SET_MORE_MY_LOBBIES', payload);
	},

	async getTopPlayers() {
		try {
			const response = await axios.get('/top-players');
			const { data } = response;
			const { result } = data;
			return result;
		} catch (error) {
			return false;
		}
	},

	async cancelFlipLobby({ commit }, payload) {
		commit('SET_LOADING', true);
		commit('SET_ERROR', null);

		try {
			const response = await axios.put(`/flip/cancel/${payload.id}`,{key:payload.key});
			const { data } = response;
			const { result: cancelledLobby } = data;
			commit('SET_LOADING', false);
			// commit('SET_LOBBY_IN_MY_LOBBIES', cancelledLobby);
			return cancelledLobby;
		} catch (error) {
			const { response } = error;
			if (!!response.data == true) {
				const { message } = response.data;
				commit('SET_LOADING', false);
				commit('SET_ERROR', message);
				return null;
			}
		}
		return null;
	}
};

export default actions;
