import state from './moduleUserState';
import mutations from './moduleUserMutations';
import actions from './moduleUserActions';
import getters from './moduleUserGetters';

const store = {
	namespaced: true,
	state,
	mutations,
	actions,
	getters
};

export default store;
