import Vue from 'vue';
import DatetimePicker from 'vuetify-datetime-picker';
import vuetify from './plugins/vuetify';
import router from './routes/routes';
import App from './App.vue';
import axios from 'axios';
import VueAxios from 'vue-axios';
import VueSession from 'vue-session';
import Vue2Filters from 'vue2-filters';
import VueCookies from 'vue-cookies';
import VueMoment from 'vue-moment';
import VueCookiesReactive from 'vue-cookies-reactive'

import store from './store/store'; // Vuex Store
import './filters'; //custom filters
import './assets/css/app.css'; //global css file

Vue.config.productionTip = false;

Vue.use(VueAxios, axios);
Vue.use(DatetimePicker);
Vue.use(VueSession, { persist: true });
Vue.use(Vue2Filters);
Vue.use(VueCookies);
Vue.use(VueMoment);
Vue.use(VueCookiesReactive)

new Vue({
	vuetify,
	router,
	store,
	render: h => h(App)
}).$mount('#app');
