import axiosConfig from '../../axios';

const { axiosPlayer: axios } = axiosConfig;

const actions = {
	/**
	 * API call for creating player
	 */
	async createPlayer({ commit }, payload) {
		let result = false;

		commit('SET_LOADING', true);
		commit('SET_ERROR', null);

		try {
			await axios
				.post('/', payload)
				.then(response => {
					/*do something successful*/
					result = true;
					console.log(response.data);
				})
				.catch(error => {
					const { response } = error;

					if (response.data && response.data.error) {
						commit('SET_ERROR', response.data.error);
					} else if (response.data && response.data.errors) {
						commit('SET_ERROR', response.data.errors[0].msg);
					}

					commit('SET_LOADING', false);
				});

			return result;
		} catch (error) {
			const { response } = error;

			if (!!response.data == true) {
				const { message } = response.data;
				commit('SET_ERROR', message);
				return false;
			}

			commit('SET_LOADING', false);
		}
	},

	async loginPlayer({ commit }, payload) {
		commit('SET_LOADING', true);
		commit('SET_ERROR', null);

		try {
			await axios
				.post('/authenticate', payload)
				.then(response => {
					/*do something successful*/
					const player = response.data;
					player.type = 'player';
					commit('SET_PLAYER', player);
				})
				.catch(error => {
					commit('SET_PLAYER', {});
					commit('SET_ERROR', error.response.data.error);
				});
		} catch (error) {
			commit('SET_PLAYER', {});
			commit('SET_ERROR', 'An error occurred');
		}

		commit('SET_LOADING', false);
	},
	async testPlayer({ commit }, payload) {
		try {
			await axios
				.get('/authenticate/test', payload)
				.then(response => {
					console.log('response', response);
				})
				.catch(error => {
					commit('SET_PLAYER', {});
					commit('SET_ERROR', error.response.data.error);
				});
		} catch (error) {
			commit('SET_PLAYER', {});
			commit('SET_ERROR', 'An error occurred');
		}
	},
	async getPlayersByAgent({ commit }, payload) {
		commit('SET_LOADING', true);
		commit('SET_ERROR', null);
		commit('SET_PLAYERS', []);

		const agentId = typeof payload == 'object' ? payload.agent_id : payload;
		const status = typeof payload == 'object' ? payload.status : 1;

		try {
			const response = await axios.get(`agent/${agentId}/${status}`);
			const { data } = response;
			const { result: players } = data;
			commit('SET_LOADING', false);
			commit('SET_PLAYERS', players);
			return players;
		} catch (error) {
			const { response } = error;
			if (!!response.data == true) {
				const { message } = response.data;
				commit('SET_LOADING', false);
				commit('SET_ERROR', message);
				return false;
			}
		}
	},
	async setError({ commit }, payload) {
		commit('SET_ERROR', payload);
	},
	async fbLogin({ commit }, payload) {
		let result = false;

		commit('SET_LOADING', true);
		commit('SET_ERROR', null);

		try {
			await axios
				.post('/fb-register', payload)
				.then(response => {
					/*do something successful*/
					result = true;
					const player = response.data.result.data;
					player.type = 'player';
					commit('SET_PLAYER', player);
				})
				.catch(error => {
					const { response } = error;

					if (response.data && response.data.message) {
						commit('SET_ERROR', response.data.message);
					} else if (response.data && response.data.errors) {
						commit('SET_ERROR', response.data.errors[0].msg);
					}

					commit('SET_LOADING', false);
				});

			return result;
		} catch (error) {
			const { response } = error;

			if (!!response.data == true) {
				const { message } = response.data;
				commit('SET_ERROR', message);
				return false;
			}

			commit('SET_LOADING', false);
		}
	},

	async getPlayerBets({ commit }, payload) {
		commit('SET_LOADING', true);
		commit('SET_ERROR', null);
		const { _id, betType,key,meta } = payload;

		try {
			const response = await axios.get(`/${_id}/${betType}/bets`, {
				params: { key ,meta}
			});
			const { data } = response;
			const { results: bets } = data;
			console.log('bets: ', bets);
			commit('SET_PLAYER_BETS', bets);
			return bets;
		} catch (error) {
			const { response } = error;
			if (!!response.data == true) {
				const { message } = response.data;
				commit('SET_LOADING', false);
				commit('SET_ERROR', message);
				return false;
			}
		}
	},

	async approvePendingPLayer({ commit }, payload) {
		commit('SET_LOADING', true);
		commit('SET_ERROR', null);
		const { _id } = payload;

		try {
			const response = await axios.put(`/${_id}`, { status: 1 });
			const { data } = response;
			const { result: player } = data;
			commit('SET_LOADING', false);
			return player;
		} catch (error) {
			const { response } = error;
			if (!!response.data == true) {
				const { message } = response.data;
				commit('SET_LOADING', false);
				commit('SET_ERROR', message);
				return false;
			}
		}
	},

	async rejectPendingPLayer({ commit }, payload) {
		commit('SET_LOADING', true);
		commit('SET_ERROR', null);
		const { _id } = payload;

		try {
			const response = await axios.put(`/${_id}`, { status: 2 });
			const { data } = response;
			const { result: player } = data;
			commit('SET_LOADING', false);
			return player;
		} catch (error) {
			const { response } = error;
			if (!!response.data == true) {
				const { message } = response.data;
				commit('SET_LOADING', false);
				commit('SET_ERROR', message);
				return false;
			}
		}
	},

	async updatePlayerAvatar({ commit }, payload) {
		commit('SET_LOADING', true);
		commit('SET_ERROR', null);
		const { _id, ...updateData } = payload;

		try {
			const response = await axios.put(`/${_id}`, updateData);
			const { data } = response;
			const { result: player, success } = data;
			commit('SET_LOADING', false);
			return {
				success,
				player
			};
		} catch (error) {
			const { response } = error;
			if (!!response.data == true) {
				const { message } = response.data;
				commit('SET_LOADING', false);
				commit('SET_ERROR', message);
				return false;
			}
		}
	},

	async uploadDisplayPhoto({ commit }, payload) {
		commit('SET_LOADING', true);
		commit('SET_ERROR', null);
		try {
			const { formData } = payload;
			const headers = {
				'Content-Type': 'multipart/form-data'
			};

			const response = await axios.post('avatar/upload-image', formData, {
				headers
			});
			const { data } = response;
			commit('SET_LOADING', false);
			commit('SET_ERROR', null);
			return data;
		} catch (error) {
			const { response } = error;
			if (!!response.data == true) {
				const { message } = response.data;
				commit('SET_LOADING', false);
				commit('SET_ERROR', message);
				return false;
			}
		}
	},

	async updatePlayerInformation({ commit }, payload) {
		commit('SET_LOADING', true);
		commit('SET_ERROR', null);
		const { _id, ...updateData } = payload;

		try {
			const response = await axios.put(`/${_id}`, updateData);
			const { data } = response;
			const { result: player, success } = data;
			commit('SET_LOADING', false);
			return {
				success,
				player
			};
		} catch (error) {
			const { response } = error;
			if (!!response.data == true) {
				const { message } = response.data;
				commit('SET_LOADING', false);
				commit('SET_ERROR', message);
				return false;
			}
		}
	}
};

export default actions;
