import state from './moduleTransactionState';
import mutations from './moduleTransactionMutations';
import actions from './moduleTransactionActions';
import getters from './moduleTransactionGetters';

const store = {
	namespaced: true,
	state,
	mutations,
	actions,
	getters
};

export default store;
