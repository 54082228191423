import axiosConfig from "../../axios";

const { axiosUser: axios } = axiosConfig;

const actions = {
  //action for updating state.user data
  setUser({ commit }, payload) {
    console.log("set user", payload);
    commit("SET_USER", payload);
  },

  /**
   *
   * API call for getting all list of users
   */
  async getUsers({ commit }) {
    const response = await axios.get("/");
    const { data } = response;
    const { result: users } = data;
    commit("SET_USERS", users);
    return users;
  },

  /**
   *
   * API call for creating new User
   */
  async createUser({ commit }, payload) {
    commit("SET_LOADING", true);
    commit("SET_ERROR", null);
    try {
      const response = await axios.post("/", payload);
      const { data } = response;
      const { result: createdUser } = data;
      commit("SET_LOADING", false);
      return createdUser;
    } catch (error) {
      const { response } = error;
      if (!!response.data == true) {
        const { message } = response.data;
        commit("SET_ERROR", message);
        return false;
      }

      commit("SET_LOADING", false);
    }
  },

  /**
   * API call for getting User by _id
   */
  async getUserById({ commit }, payload) {
    commit("SET_LOADING", true);
    commit("SET_ERROR", null);

    const id = payload;

    try {
      const response = await axios.get(`/${id}`);
      const { data } = response;
      const { result: user } = data;
      commit("SET_USER", user);
      commit("SET_LOADING", false);
      return user;
    } catch (error) {
      const { response } = error;
      if (!!response.data == true) {
        const { message } = response.data;
        commit("SET_ERROR", message);
        return false;
      }

      commit("SET_LOADING", false);
    }
  },

  /**
   * API call for updating User
   */

  async updateUser({ commit }, payload) {
    commit("SET_LOADING", true);
    commit("SET_ERROR", null);
    try {
      console.log("updateUser payload: ", payload);

      const { id, ...userUpdatedData } = payload;
      const response = await axios.put(`/${id}`, userUpdatedData);
      const { data } = response;
      const { result: user } = data;
      commit("SET_USER", user);
      commit("SET_LOADING", false);
      console.log("user : ", user);
      return user;
    } catch (error) {
      const { response } = error;
      if (!!response.data == true) {
        const { message } = response.data;
        commit("SET_ERROR", message);
        return false;
      }

      commit("SET_LOADING", false);
    }
  },
  /**
   * //login user API
   * @param {*} param0
   * @param {*} payload
   */
  async loginUser({ commit }, payload) {
    commit("SET_LOADING", true);
    commit("SET_ERROR", null);

    try {
      await axios
        .post("/authenticate", payload)
        .then(response => {
          /*do something successful*/
          const user = response.data;
          commit("SET_USER", user);
        })
        .catch(error => {
          commit("SET_USER", {});
          commit("SET_ERROR", error.response.data.error);
        });
    } catch (error) {
      commit("SET_USER", {});
      commit("SET_ERROR", "An error occurred");
    }

    commit("SET_LOADING", false);
  },
};

export default actions;
