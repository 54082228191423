const mutations = {
	SET_LOADING: (state, payload) => {
		state.loading = payload;
	},

	SET_ERROR: (state, payload) => {
		state.error = payload;
	},

	SET_PLAYERS: (state, payload) => {
		state.players = payload;
	},

	SET_PLAYER: (state, payload) => {
		state.player = payload;
	},

	SET_NEW_PLAYER: (state, payload) => {
		state.newPlayer = payload;
	},

	SET_PLAYER_BETS: (state, payload) => {
		state.playerBets = payload;
	}
};

export default mutations;
