import axiosConfig from "../../axios";

const { axiosAgent: axios } = axiosConfig;

const actions = {
  //action for updating state.agent data
  setAgent({ commit }, payload) {
    commit("SET_AGENT", payload);
  },

  /**
   *
   * API call for getting all list of agents
   */
  async getAgents({ commit }) {
    const response = await axios.get("/");
    const { data } = response;
    const { result: agents } = data;
    commit("SET_AGENTS", agents);
    return agents;
  },

  /**
   *
   * API call for creating new agent
   */
  async createAgent({ commit }, payload) {
    commit("SET_LOADING", true);
    commit("SET_ERROR", null);
    try {
      const response = await axios.post("/", payload);
      const { data } = response;
      const { result: createdAgent } = data;
      commit("SET_LOADING", false);
      return createdAgent;
    } catch (error) {
      const { response } = error;
      if (!!response.data == true) {
        const { message } = response.data;
        commit("SET_ERROR", message);
        return false;
      }

      commit("SET_LOADING", false);
    }
  },

  /**
   * API call for getting agent by _id
   */
  async getAgentById({ commit }, payload) {
    commit("SET_LOADING", true);
    commit("SET_ERROR", null);

    const id = payload;

    try {
      const response = await axios.get(`/${id}`);
      const { data } = response;
      const { result: agent } = data;
      commit("SET_AGENT", agent);
      commit("SET_LOADING", false);
      return agent;
    } catch (error) {
      const { response } = error;
      if (!!response.data == true) {
        const { message } = response.data;
        commit("SET_ERROR", message);
        return false;
      }

      commit("SET_LOADING", false);
    }
  },

  /**
   * API call for updating agent
   */

  async updateAgent({ commit }, payload) {
    commit("SET_LOADING", true);
    commit("SET_ERROR", null);
    try {
      console.log("updateAgent payload: ", payload);

      const { id, ...agentUpdatedData } = payload;
      const response = await axios.put(`/${id}`, agentUpdatedData);
      const { data } = response;
      const { result: agent } = data;
      commit("SET_AGENT", agent);
      commit("SET_LOADING", false);
      console.log("agent : ", agent);
      return agent;
    } catch (error) {
      const { response } = error;
      if (!!response.data == true) {
        const { message } = response.data;
        commit("SET_ERROR", message);
        return false;
      }

      commit("SET_LOADING", false);
    }
  },
  /**
   * //login agent API
   * @param {*} param0
   * @param {*} payload
   */
  async loginAgent({ commit }, payload) {
    commit("SET_LOADING", true);
    commit("SET_ERROR", null);

    try {
      await axios
        .post("/authenticate", payload)
        .then(response => {
          /*do something successful*/
          const agent = response.data;
          agent.type = "agent";
          commit("SET_AGENT", agent);
        })
        .catch(error => {
          console.log("xxx", error.response);
          commit("SET_AGENT", {});
          commit("SET_ERROR", error.response.data.error);
        });
    } catch (error) {
      commit("SET_AGENT", {});
      commit("SET_ERROR", "An error occurred");
    }

    commit("SET_LOADING", false);
  },

  async getAgentsByAgent({ commit }, payload) {
    commit("SET_LOADING", true);
    commit("SET_ERROR", null);
    commit("SET_AGENTS", []);

    try {
      const response = await axios.get("/agent-under/" + payload);
      const { data } = response;
      const { result: agents } = data;
      commit("SET_AGENTS", agents);
      commit("SET_LOADING", false);
      return agents;
    } catch (error) {
      commit("SET_LOADING", false);
      commit("SET_AGENTS", []);
      commit("SET_ERROR", "An error occurred");
    }
  },

  async getAgentsByLevel({ commit }, payload) {
    try {
      await axios
        .get("/level/" + payload)
        .then(response => {
          /*do something successful*/
          commit("SET_AGENTS", response.data.result);
        })
        .catch(error => {
          commit("SET_AGENTS", []);
          commit("SET_ERROR", error.response.data.error);
        });

      commit("SET_LOADING", false);
    } catch (error) {
      commit("SET_LOADING", false);
      commit("SET_AGENTS", []);
      commit("SET_ERROR", "An error occurred");
    }
  },

  /**
   * API call for getting agent commissions
   */
  async getAgentCommissions({ commit }, payload) {
    commit("SET_LOADING", true);
    commit("SET_COMMISSIONS",[]);
    commit("SET_ERROR", null);

    const id = payload.id;
    console.log("payload", payload);
    const count = payload.count;
    console.log("payload count", count);
    try {
      const response = await axios.get(`/commissions/logs/${id}/${count}`);
      const { data } = response;
      console.log("data", data);
      const commissions = data.result.commissions;
      commit("SET_COMMISSIONS", response.data.result.commissions)
      commit("SET_MAXLOG", response.data.result.count);
      commit("SET_LOADING", false);
      return commissions;
    } catch (error) {
      const { response } = error;
      if (!!response.data == true) {
        const { message } = response.data;
        commit("SET_ERROR", message);
        return false;
      }

      commit("SET_LOADING", false);
    }
  },

  async getUnpaidCommissions({ commit }, payload) {
    commit("SET_LOADING", true);
    commit("SET_ERROR", null);

    const id = payload;

    try {
      const response = await axios.get(`/commissions/unpaid/${id}`);
      const { data } = response;
      const { result: commissions } = data;
      commit("SET_LOADING", false);
      return commissions;
    } catch (error) {
      const { response } = error;
      if (!!response.data == true) {
        const { message } = response.data;
        commit("SET_ERROR", message);
        return false;
      }

      commit("SET_LOADING", false);
    }
  },
};

export default actions;
