export function initials(value) {
	if (!!value == false) {
		return value;
	}
	return value
		.split(' ')
		.map(val => {
			return val.substring(0, 1);
		})
		.join('');
}

export function firstWord(value) {
	return value.split(' ')[0];
}
