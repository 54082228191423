import Vue from 'vue';
import Vuex from 'vuex';
import Cookies from 'js-cookie';
import createPersistedState from 'vuex-persistedstate';
import agent from './agent/moduleAgent';
import player from './player/modulePlayer';
import lobby from './lobby/moduleLobby';
import user from './user/moduleUser';
import auth from './auth/moduleAuth';
import transaction from './transaction/moduleTransaction';

Vue.use(Vuex);
const debug = process.env.NODE_ENV !== 'production';
export default new Vuex.Store({
	modules: {
		agent,
		player,
		lobby,
		user,
		auth,
		transaction
	},
	strict: debug,
	plugins: [
		createPersistedState({
			paths: ['auth'],
			storage: {
				getItem: key => Cookies.get(key),
				setItem: (key, value) => Cookies.set(key, value, { expires: 3, secure: false }),
				removeItem: key => Cookies.remove(key)
			}
		})
	]
});
