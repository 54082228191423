import state from './modulePlayerState';
import mutations from './modulePlayerMutations';
import actions from './modulePlayerActions';
import getters from './modulePlayerGetters';

const store = {
	namespaced: true,
	state,
	mutations,
	actions,
	getters
};

export default store;
